import type { CSSProperties } from 'vue';
import type {
  GameSwipeAdvancedData,
  GameSwipeButtonData,
  GameSwipeGeneralData,
  GameSwipeGeneralDeviceData,
  GameSwipeGeneralMessageData,
  GameSwipeGeneralStatisticsData,
  GameSwipeitData,
  GameSwipePicturesData,
  GameSwipePicturesPictureData
} from '@/src/components/games/swipeit/Data';
import useDevice, { getDeviceData } from '@/src/hooks/useDevice';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import {
  GameIndicatorIcon,
  GameIndicatorPositionType,
  GameIndicatorSettingsModel
} from '@/src/components/indicators/Model';
import type { GameIndicator, GameTimeChallenge, HasGameTimeChallenge, HasSound } from '@/src/models/GameModel';
import { GameModel } from '@/src/models/GameModel';
import { DeviceTypes } from '@/src/typings/enums/enums';
import type { GameSwipeItButtonsType, SwipeDescriptionPositionTypes } from '@/src/components/games/swipeit/enums';
import { SwipeLayoutTypes } from '@/src/components/games/swipeit/enums';
import { transformMeasurementToNumber } from '@/src/utilities/Utilities';
import type { TransitionTypes } from '@/src/typings/types/types';
import { SdkSwipeitPictureModel } from '@/src/components/games/swipeit/sdk/picture';
import type { GameSwipeitSdkSettings } from '@/src/components/games/swipeit/sdk/exports';
import { useCampaignStore } from '@/src/store/campaign';
import type { MetricData } from '@/src/store/campaign';

interface GameSwipeitLayoutBorderState {
  color: string;
  width: number;
}

export interface GameSwipeitLayoutState {
  border?: GameSwipeitLayoutBorderState;
  degrees?: number;
  type: SwipeLayoutTypes;
}

interface GameSwipeitMessageState {
  left?: string;
  leftColor?: string;
  right?: string;
  rightColor?: string;
}

export interface GameSwipeItButtonsState {
  color?: string;
  enabled: boolean;
  leftText?: string;
  leftImage?: string;
  rightImage?: string;
  rightText?: string;
  onlyFacebook?: boolean;
  type?: GameSwipeItButtonsType;
}

interface GameSwipeitGeneralState {
  enableBestTime?: boolean;
  enableTimeRanking?: boolean;
  imagesLimit: number;
  layout: GameSwipeitLayoutState;
  message?: GameSwipeitMessageState;
  pictures: number;
  rightSwipes: number;
  width?: GameSwipeitGeneralWidthState;
  device?: DeviceTypes;
}

export interface GameSwipeitGeneralWidthState {
  width: number;
  isWidthPercentage: boolean;
}

export interface GameSwipeitState {
  alias: string;
  general: GameSwipeitGeneralState;
  buttons: GameSwipeItButtonsState;
  indicators?: GameIndicatorSettingsModel;
  picture?: GameSwipePictureState;
  advanced: GameSwipeAdvancedState;
  elementStyling: {
    classStyle?: Record<string, boolean>;
    border?: CSSProperties;
  };
}

export interface GameSwipePictureCardsState {
  id: number;
  image: string;
  description?: string;
  url?: string;
  statistics?: {
    left: number;
    right: number;
  };
}

export interface GameSwipeAdvancedSoundState {
  enabled: boolean;
  src?: string;
}

export interface GameSwipeAdvancedAnimationState {
  enabled: boolean;
  type?: TransitionTypes;
}

export interface GameSwipeAdvancedState {
  sound: GameSwipeAdvancedSoundState;
  animation: GameSwipeAdvancedAnimationState;
}

export interface GameSwipePictureState {
  additionalDescription?: string;
  descriptionPosition: SwipeDescriptionPositionTypes;
  enableUrl?: boolean;
  cards?: GameSwipePictureCardsState[];
}

export class GameSwipeitModel
  extends GameModel<GameSwipeitData, GameSwipeitState>
  implements HasGameTimeChallenge, HasSound
{
  parseGame(data: GameSwipeitData) {
    const state = this.state;

    state.general = GameSwipeitModel.constructGeneralState(data.general);
    state.general.width = GameSwipeitModel.parseGeneralMobileData(data.general);
    state.general.message = GameSwipeitModel.constructGeneralMessageState(data.general.message);
    state.buttons = GameSwipeitModel.parseGeneralButtonsData(data.general) || { enabled: false };
    state.picture = GameSwipeitModel.constructPicturesState(data.pictures);
    state.picture.cards = state.picture.cards || [];

    // we are reversing the array bc the last item will be displayed first based on the current setup/style
    // so we need to have the first item last and that's why we are reversing the array
    Object.values<GameSwipePicturesPictureData>(data.pictures.picture)
      .reverse()
      .forEach((val, index) => {
        if (state.picture) {
          state.picture.cards = state.picture.cards || [];
          state.picture.cards[Number(index)] = GameSwipeitModel.constructPictureCardsState(
            val,
            data.general.statistics
          );
        }
      });

    state.picture.cards = state.picture.cards.slice(-state.general.pictures);

    state.elementStyling = state.elementStyling ?? {};

    if (state.general.layout.border) {
      state.elementStyling.border = GameSwipeitModel.constructCardBorderStyling(state.general.layout.border);
    }

    state.advanced = state.advanced ?? {};
    state.advanced.sound = GameSwipeitModel.constructAdvancedSoundState(data?.advanced);
    state.advanced.animation = GameSwipeitModel.constructAdvancedAnimationState(data?.advanced);

    if (data.indicators) {
      if (state.indicators) {
        state.indicators.setData(data.indicators);
      } else {
        state.indicators = new GameIndicatorSettingsModel(data.indicators);
      }
    }

    if (state?.general?.layout) {
      state.elementStyling.classStyle = GameSwipeitModel.constructCardClassStyling(state.general.layout);
    }

    if (state.general.imagesLimit) {
      state.picture.cards = state.picture.cards.slice(0, state.general.imagesLimit);
    }
  }

  private static constructAdvancedAnimationState(
    data: GameSwipeAdvancedData | undefined
  ): GameSwipeAdvancedAnimationState {
    return {
      enabled: !!data?.animation,
      ...(data?.animation && { type: data.animation })
    };
  }

  private static constructAdvancedSoundState(data: GameSwipeAdvancedData | undefined): GameSwipeAdvancedSoundState {
    return {
      enabled: !!data?.sound,
      ...(data?.sound && { src: data.sound })
    };
  }

  private static constructCardClassStyling(state: GameSwipeitLayoutState): Record<string, boolean> {
    const cardClassList: Record<string, boolean> = {};
    if (state.type === SwipeLayoutTypes.DEFAULT) {
      cardClassList['swipeit swipeit--layout-default'] = true;
    }

    if (state.type === SwipeLayoutTypes.ONLY_IMAGE) {
      cardClassList['swipeit--layout-only-image'] = true;
    }

    cardClassList[`swipeit--degrees-${state?.degrees ?? 0}`] = true;

    return { ...cardClassList };
  }

  private static constructCardBorderStyling(state: GameSwipeitLayoutBorderState): CSSProperties {
    return {
      borderWidth: `${state.width}px`,
      borderColor: state.color
    };
  }

  private static constructGeneralState(data: GameSwipeGeneralData): GameSwipeitGeneralState {
    return {
      ...(data?.enable_best_time && {
        enableBestTime: data.enable_best_time === '1'
      }),
      ...(data?.enable_time_ranking && {
        enableTimeRanking: data.enable_time_ranking === '1'
      }),
      imagesLimit: Number(data.images_limit),
      pictures: Number(data.pictures),
      rightSwipes: Number(data.right_swipes),
      layout: {
        ...(data.layout.border && {
          border: {
            width: Number(data.layout.border.width),
            color: data.layout.border.color
          }
        }),
        type: data.layout.type,
        ...(data.layout.degrees && {
          degrees: Number(data.layout.degrees)
        })
      }
    };
  }

  private static constructGeneralMessageState(data: GameSwipeGeneralMessageData): GameSwipeitMessageState {
    return {
      ...(data?.left && { left: data.left }),
      ...(data?.left_color && { leftColor: data.left_color }),
      ...(data?.right && { right: data.right }),
      ...(data?.right_color && { rightColor: data.right_color })
    };
  }

  private static constructPicturesState(data: GameSwipePicturesData): GameSwipePictureState {
    return {
      ...(data.enable_url && { enableUrl: data.enable_url === '1' }),
      ...(data.additional_description && {
        additionalDescription: data.additional_description
      }),
      ...(data.description_position && {
        descriptionPosition: data.description_position
      })
    };
  }

  private static constructPictureCardsState(
    data: GameSwipePicturesPictureData,
    statistics?: GameSwipeGeneralStatisticsData
  ): GameSwipePictureCardsState {
    return {
      id: Number(data.id),
      image: data.image,
      ...(data.description && { description: data.description }),
      ...(data.url && { url: data.url }),
      ...(statistics && statistics[data.id] && { statistics: statistics[data.id] })
    };
  }

  private static constructGameSwipeWidthState(
    data: GameSwipeGeneralDeviceData
  ): GameSwipeitGeneralWidthState | undefined {
    const isWidthPercentage = data.width.includes('%');

    const width = transformMeasurementToNumber(data.width);

    return {
      width,
      isWidthPercentage
    };
  }

  private static parseGeneralMobileData(data: GameSwipeGeneralData): GameSwipeitGeneralWidthState | undefined {
    if (data?.width) {
      const useData = this.getMobileDeviceData(data);

      if (!useData) {
        return undefined;
      }

      return GameSwipeitModel.constructGameSwipeWidthState(useData);
    }

    return undefined;
  }

  private static parseGeneralButtonsData(data: GameSwipeGeneralData): GameSwipeItButtonsState | undefined {
    if (data.buttons) {
      const useData = getDeviceData<GameSwipeButtonData>(data.buttons);

      if (!useData) {
        return undefined;
      }

      return GameSwipeitModel.constructGameSwipeButtonsState(useData);
    }

    return undefined;
  }

  private static constructGameSwipeButtonsState(data: GameSwipeButtonData): GameSwipeItButtonsState | undefined {
    if (data) {
      return {
        enabled: data.enabled === '1',
        color: data.color,
        leftText: data.left_text,
        rightText: data.right_text,
        ...(data.left_image && {
          leftImage: data.left_image
        }),
        ...(data.right_image && {
          rightImage: data.right_image
        }),
        onlyFacebook: data.only_facebook === '1',
        type: data.type
      };
    }
    return data;
  }

  public static getMobileDeviceData(data: GameSwipeGeneralData): GameSwipeGeneralDeviceData {
    const desktopData = data;

    const { isMobile } = useDevice();

    const overwriteMobile = typeof desktopData.overwrite_mobile === 'undefined' || desktopData.overwrite_mobile === '1';

    if (isMobile && !overwriteMobile && data.width.mobile) {
      return {
        ...{ width: data.width.mobile },
        ...{ type: DeviceTypes.MOBILE }
      };
    }

    return {
      ...{ width: data.width.desktop },
      ...{ type: DeviceTypes.DESKTOP }
    };
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [
      {
        indicatorKey: 'time',
        metricKey: {
          time_used: 'timeused',
          time_left: 'timeleft'
        },
        icon: GameIndicatorIcon.TIME,
        value: {
          time_used: 0,
          time_left: this.state.timeChallenge?.limit || 60
        }
      }
    ];
  }

  parseTimeChallenge(): GameTimeChallenge | undefined {
    const data = this.getData();

    return {
      enabled: data.general.time === 1,
      ...(data.general.time_limit && { limit: Number(data.general.time_limit) })
    };
  }

  public isGameValid(): boolean {
    return true;
  }

  public getSounds(): string[] {
    if (!this.state.advanced.sound.enabled) {
      return [];
    }

    const sounds: string[] = [];

    if (this.state.advanced.sound.src) {
      sounds.push(this.state.advanced.sound.src);
    }

    return sounds;
  }

  public get sdkMetrics(): MetricData | undefined {
    const metrics: MetricData = {};

    const campaignStore = useCampaignStore();

    for (const metricKey in campaignStore.metricData) {
      if (Object.prototype.hasOwnProperty.call(campaignStore.metricData, metricKey)) {
        const metricValue = campaignStore.metricData[`${metricKey}`];

        if (metricKey.startsWith('right_swipe_') || metricKey.startsWith('left_swipe_')) {
          metrics[`${metricKey}`] = metricValue;
        }
      }
    }

    if (Object.keys(metrics).length > 0) {
      return metrics;
    }

    return undefined;
  }

  public get sdkSettings(): GameSwipeitSdkSettings {
    return {
      pictures:
        this.state.picture?.cards?.map((card) => {
          return new SdkSwipeitPictureModel(card);
        }) ?? []
    };
  }
}
